import Base from '@/libs/core/Base'

const fields = {
  name: '',
  description: '',
}

export default class Product extends Base {
  constructor () {
    super(fields)
  }

  store () {
    return new Promise(async (resolve, reject) => {
      try {
        let response = await this.form.submit('post', 'insurance/products', this.getFields())
        this.setFields(fields)
        flash(response)
        resolve(response)
      } catch (err) {
        reject(err)
      }
    })
  }

  update (productId) {
    return new Promise(async (resolve, reject) => {
      try {
        let response = await this.form.submit('patch', `insurance/products/${productId}`, this.getFields())
        flash(response)
        resolve(response)
      } catch (err) {
        reject(err)
      }
    })
  }
}