<template>
  <dialog-form
    :dialog="dialog"
    :loading="loading"
    :title="title"
    actionText="Save product details"
    @close="$emit('close')"
    @submit="submit()"
  >
    <template #content>
      <v-text-field
        dense
        outlined
        persistent-hint
        label="Name *"
        class="rounded-lg"
        v-model="productObj.name"
        :hint="errors.get('name')"
        :error="errors.has('name')"
        @input="errors.clear('name')"
      ></v-text-field>

      <v-textarea
        dense
        outlined
        persistent-hint
        label="Description"
        class="rounded-lg"
        v-model="productObj.description"
        :hint="errors.get('description')"
        :error="errors.has('description')"
        @input="errors.clear('description')"
      ></v-textarea>
    </template>
  </dialog-form>
</template>

<script>
import Product from '@/libs/insurance/Product'
import { mapActions } from 'vuex'

export default {
  props: {
    dialog: {
      required: true,
      type: Boolean,
    },

    product: {
      type: Object
    }
  },

  data () {
    return {
      loading: false,
      productObj: new Product(),
    }
  },

  computed: {
    errors () {
      return this.productObj.form.errors
    },

    title () {
      const action = this.product ? 'Edit' : 'Create'
      return `${action} Product`
    },
  },

  methods: {
    ...mapActions([
      'setInsuranceProducts'
    ]),

    mapProduct () {
      if (this.product) {
        Object.keys(this.product).forEach(key => {
          if (this.productObj.hasOwnProperty(key)) {
            this.productObj[key] = this.product[key]
          }
        })
      }
    },

    submit () {
      if (!this.loading) {
        this.loading = true
        !this.product ? this.store() : this.update()
      }
    },

    store () {
      this.productObj.store().then(() => {
        // this.setFarmResources().then(() => {
        //   this.$emit('close')
        // })
      }).finally(() => {
        this.loading = false
      })
    },

    update () {
      this.productObj.update(this.product.product_uid).then(() => {
        this.setInsuranceProducts().then(() => {
          this.$emit('close')
        })
      }).finally(() => {
        this.loading = false
      })
    },
  },

  mounted () {
    this.mapProduct()
  }
}
</script>